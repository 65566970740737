<template>
  <div class="wrapper">
    <div>
      <CToaster :autohide="3000">
        <template v-for="info in infoList">
          <CToast
            :key="info.message"
            :show="true"
            :header="info.header"
            :color="info.color"
          >
            {{ info.message }}.
          </CToast>
        </template>
      </CToaster>
    </div>
    <div>
      <CRow>
        <CCol>
          <CCard>
            <CCardHeader>
              <strong>Additional Filter</strong>
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol>
                  <CInput v-model="priceMinFilter" label="Price Min" placeholder="20000" />
                </CCol>
                <CCol>
                  <CInput v-model="priceMaxFilter"  label="Price Max" placeholder="500000" />
                </CCol>
             
              </CRow>

              <!-- Row for Button -->
              <CRow class="d-flex justify-content-end mt-3">
                <CCol sm="auto">

                  <CButton
                        size="sm"
                        color="secondary"
                        class="ml-1"
                        @click="onCleanerClick"
                      >
                        Clear
                      </CButton>
                      <CButton
                        size="sm"
                        color="info"
                        class="ml-1"
                        @click="refreshTable"
                      >
                        Search
                      </CButton>


                
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="12">
          <CCard>
            <CCardHeader> <strong> Sales </strong> List </CCardHeader>
            <CCardBody>
              <CDataTable
                :items="computedItems"
                :fields="fields"
                :column-filter="{ external: true, lazy: true }"
                :column-filter-value.sync="columnFilterValue"
                :sorter="{ external: true, resetable: true }"
                :sorter-value.sync="sorterValue" 
                :items-per-page="itemsPerPage"
                :active-page="1"
                hover
                :loading="loading"
              >
                <template #show_index="{ index }">
                  <td class="py-2">
                    {{ index + 1 }}
                  </td>
                </template>
                <!-- <template #latest_stock_status="{ item }">
                  <td>
                    <CBadge :color="getBadge(getLatesStockStatusName(item))">{{
                      getLatesStockStatusName(item)
                    }}</CBadge>
                  </td>
                </template>
                <template #status_name="{ item }">
                  <td>
                    <CBadge :color="getBadge(item.stockStatus.name)">{{
                      item.stockStatus.name
                    }}</CBadge>
                  </td>
                </template> -->

                <template #show_details="{ item, index }">
                  <td class="py-2">
                    <CButton
                      color="primary"
                      variant="outline"
                      square
                      size="sm"
                      @click="toggleDetails(item, index)"
                    >
                      {{ Boolean(item._toggled) ? "Hide" : "Show" }}
                    </CButton>
                  </td>
                </template>
                <template #details="{ item }">
                  <CCollapse
                    :show="Boolean(item._toggled)"
                    :duration="collapseDuration"
                  >
                    <CCardBody>
                      <!-- <p class="text-muted">Code: {{ item.code }}</p>
                      <p class="text-muted">Name: {{ item.name }}</p> -->
                      <CButton
                        size="sm"
                        color="info"
                        class="ml-1"
                        @click="onEdit(item)"
                      >
                        Edit
                      </CButton>

                      <!-- <CButton
                        size="sm"
                        color="info"
                        class="ml-1"
                        @click="onDetails(item)"
                      >
                        Details
                      </CButton> -->
                    </CCardBody>
                  </CCollapse>
                </template>
              </CDataTable>
            </CCardBody>
          </CCard>
          <!-- <CModal
            title="Confirm Delete"
            color="warning"
            :show.sync="warningModal"
            @update:show="onDeleteConfirmation"
          >
            Are you sure you want to delete this {{ itemToDelete.fullName }} ?
          </CModal> -->
        </CCol>
      </CRow>
    </div>
  </div>
</template>

<script>
import StockApi from "../../lib/stockApi";
import moment from "moment";

export default {
  name: "StockList",
  data() {
    return {
      loadedItems: [],
      columnFilterValue: {},
      tableFilterValue: "",
      sorterValue: { column: null, asc: true },
      // Pagination
      activePage: 1,
      itemsPerPage: 20,
      pages: 1,

      // Filter states
      priceMinFilter: "",
      priceMaxFilter: "",
      // brandFilter: "",
      // modelFilter: "",
      // statusFilter: "",
      loading: true,
      items: [],
      infoList: [],
      fields: [
        {
          key: "show_index",
          label: "#",
          _style: "width:1%",
          sorter: false,
          filter: false,
        },
        { key: "vehicleRegistrationDate", label: "Reg. Date" ,sorter: false},
        { key: "vehicleRegistrationNumber", label: "Reg. No" ,sorter: false},
        { key: "stockNo" },
        { key: "brandModelName", label: "Model" ,sorter: false},
        { key: "chassisNo", label: "Chassis" ,sorter: false},
        { key: "customerName", label: "Customer" ,sorter: false},
        { key: "salesmanName", label: "Salesman" ,sorter: false},
        { key: "bodyPrice", label: "Body Price",  filter:false,sorter: false },
        { key: "sellingPrice", label: "Selling Price",  filter:false ,sorter: false},
        { key: "purchaseCost", label: "Purchase Cost" ,  filter:false,sorter: false},
        { key: "grossProfit", label: "Gross Profit",  filter:false,sorter: false },
        { key: "hirePurchase", label: "Hire Purchase" ,  filter:false,sorter: false},
        { key: "salesmenCommision", label: "Salesmen Com.",  filter:false ,sorter: false},
        { key: "promotion", label: "Promotion (RTAX)",  filter:false ,sorter: false},
        { key: "netProfit", label: "Net Profit",  filter:false ,sorter: false},
        { key: "loanAmount", label: "Loan Amount",  filter:false ,sorter: false},
        { key: "loanBank", label: "Loan Bank",  filter:false,sorter: false },
        { key: "insuranceAmount", label: "Insurance" ,  filter:false,sorter: false},
        { key: "othersAmount", label: "Puspakom, Reg. & RTAX" ,  filter:false,sorter: false},
        // {
        //   key: "latest_stock_status",
        //   _style: "min-width:100px;",
        //   label: "Status",
        // },

        // { key: "createdOn" },
        // { key: "name", _style: "min-width:200px;" },
        {
          key: "show_details",
          label: "",
          _style: "width:1%",
          sorter: false,
          filter: false,
        },
      ],
      details: [],
      collapseDuration: 0,
      api: new StockApi(),
      warningModal: false,
      itemToDelete: {},
    };
  },
  computed: {
    reloadParams() {
      return [
        this.sorterValue,
        this.columnFilterValue,
        this.tableFilterValue,
        this.activePage,
        this.itemsPerPage,
      ];
    },
    computedItems() {
      return this.loadedItems.map((item) => {
        return {
          ...item,
          vehicleRegistrationDate: this.getDisplayDate(
            item.registration.vehicleRegistrationDate
          ),
          vehicleRegistrationNumber:
            item.registration.vehicleRegistrationNumber,
          brandModelName: this.getBrandModelName(item),
          chassisNo: item.vehicle.chasisNo,
          customerName: this.getCustomerName(item),
          salesmanName: this.getSalesmanName(item),
          bodyPrice: item.purchase.bodyPriceLocalCurrency,
          sellingPrice: item.sale.saleAmount,
          purchaseCost: this.getPurchaseCost(item),
          grossProfit: this.getGrossProfit(item),
          hirePurchase: item.sale.hpAmount.toFixed(2),
          salesmenCommision: item.sale.salesmanCommisionAmount.toFixed(2),
          promotion: item.sale.promotionDiscountAmount.toFixed(2),
          netProfit: this.getNetProfit(item),
          loanAmount: this.getLoanAmount(item),
          loanBank: this.getLoanBank(item),
          insuranceAmount: item.adminitrativeCost.insuranceAmount,
          othersAmount: item.adminitrativeCost.puspakomRegRoadTax,
        };
      });
    },
  },
  mounted() {
    var self = this;
    self.refreshTable();
  },
  watch: {
    reloadParams() {
      this.refreshTable();
    },
  },

  methods: {
    onCleanerClick()
    {
      this.priceMinFilter = "";
      this.priceMaxFilter = "";
      this.columnFilterValue = {}; // Reset column-specific filters
      this.tableFilterValue = ""; // Reset global table filter
      this.refreshTable(); // Reload data or reset filtered items

    },
    getLoanBank(item) {
      try {
        return item.sale.loan.bank.name;
      } catch (error) {}
      return "N/A";
    },
    getLoanAmount(item) {
      try {
        return item.sale.loan.approvedLoanAmount;
      } catch (error) {}
      return 0;
    },
    getNetProfit(item) {
      let grossProfit = Number(this.getGrossProfit(item));
      let hirePurchase = item.sale.hpAmount;

      let commision = item.sale.salesmanCommisionAmount;
      let promotion = item.sale.promotionDiscountAmount;
      let totalAdd = grossProfit + hirePurchase + promotion;
      let netProfit = totalAdd - commision;

      console.log("grossProfit", grossProfit);
      console.log("commision", commision);
      console.log("promotion", promotion);
      console.log("totalAdd", totalAdd);
      console.log("netProfit", netProfit);

      return netProfit.toFixed(2);
    },
    getGrossProfit(item) {
      // let sellingPrice = item.sale.saleAmount;
      let bodyPrice = item.purchase.bodyPriceLocalCurrency;
      let purchaseCost = this.getPurchaseCost(item);
      let grossProfit = bodyPrice - purchaseCost;
      return grossProfit.toFixed(2);
    },
    getPurchaseCost(item) {
      try {
        let totalAmount =
          item.purchase.vehiclePriceLocalCurrency +
          item.adminitrativeCost.dutyCostAmount +
          item.adminitrativeCost.apCostAmount +
          item.adminitrativeCost.forwardingCostAmount +
          item.expense.serviceEzCareCostAmount +
          item.expense.interiorCostAmount +
          item.expense.paintCostAmount +
          item.expense.tyreCostAmount +
          item.expense.rentalCostAmount +
          item.adminitrativeCost.intFsCostAmount;

        return totalAmount;
      } catch (error) {
        console.log("getPurchaseCost", error);
      }
      return 0;
    },
    onEdit(item) {
      var self = this;
      self.$router.push({
        path: `/admins/salesReport/${item.id}`,
      });
    },

    getSalesmanName(item) {
      try {
        return item.sale.salesmanName;
      } catch (error) {
        return "N/A";
      }
    },
    getCustomerName(item) {
      try {
        return item.sale.customer.name;
      } catch (error) {
        return "N/A";
      }
    },
    getBrandModelName(item) {
      try {
        return item.vehicle.brand.name + " " + item.vehicle.model.name;
      } catch (error) {
        return "N/A";
      }
    },
    getDisplayDate(item) {
      return moment(item).format("YYYY/MM/DD");
    },
    getLatesStockStatusName(item) {
      if (item.latestStockStatus == null) return "N/A";
      if (item.latestStockStatus.stockStatus == null) return "N/A";
      return item.latestStockStatus.stockStatus.name;
    },
    getBadge(status) {
      return status === "Draft"
        ? "primary"
        : status === "Registered"
        ? "secondary"
        : status === "Shipped"
        ? "warning"
        : status === "ArriveAtPort"
        ? "success"
        : status === "ShowRoom"
        ? "info"
        : status === "Available"
        ? "danger"
        : status === "Booked"
        ? "light"
        : status === "Cancelled"
        ? "dark"
        : "";

      //'primary','secondary','warning','success','info','danger','light','dark'
    },
    download() {},
    toast(header, message, color) {
      var self = this;
      self.infoList.push({
        header: header,
        message: message,
        color: color,
      });
    },
    toggleDetails(item, index) {
      this.$set(item, "_toggled", !item._toggled);
      this.collapseDuration = 300;
      this.$nextTick(() => {
        this.collapseDuration = 0;
      });
    },
    // refreshTable() {
    //   var self = this;
    //   const filters = {
    //     brand: this.brandFilter,
    //     model: this.modelFilter,
    //     status: this.statusFilter,
    //   };

    //   self.loading = true;
    //   self.api
    //     .getListByFilter(filters)
    //     .then((response) => {
    //       self.items = response.result;
    //       console.log(self.items);
    //       self.loading = false;
    //     })
    //     .catch(({ data }) => {
    //       self.toast("Error", helper.getErrorMessage(data), "danger");
    //       self.loading = false;
    //     });
    // },
    refreshTable() {
      this.loading = true;

      const params = {
        page: this.activePage,
        itemsPerPage: this.itemsPerPage,

        sorter: this.sorterValue,
        filters: this.columnFilterValue,
        search: this.tableFilterValue,

        priceMin: this.priceMinFilter,
        priceMax: this.priceMaxFilter,
      };

      this.api
        .getList(params)
        .then((response) => {
          if (response.result) {
            this.loadedItems = response.result.items;
            this.pages = Math.ceil(response.result.totalItems / this.itemsPerPage);
            console.log("Loaded items:", this.loadedItems);
            console.log("Total pages:", this.pages);
          } else {
            this.loadedItems = [];
            this.pages = 1;
          }
          this.loading = false;
        })
        .catch((error) => {
          this.toast("Error", "Failed to load data", "danger");
          this.loading = false;
        });
    },
    onDetails(item) {
      var self = this;
      self.$router.push({
        path: `/admins/Disburstment/${item.id}`,
      });
    },
    onDeleteConfirmation(status, evt, accept) {
      var self = this;
      if (accept) {
        this.api
          .delete(self.itemToDelete.id)
          .then((response) => {
            self.refreshTable();
          })
          .catch(({ data }) => {
            self.toast("Error", helper.getErrorMessage(data), "danger");
          });
      }
      self.itemToDelete = {};
    },
    showDeleteConfirmation(item) {
      var self = this;
      self.itemToDelete = item;
      self.warningModal = true;
    },
    addNew() {
      this.$router.push({ path: "/admins/Disburstment" });
    },
    toast(header, message, color) {
      var self = this;
      self.infoList.push({
        header: header,
        message: message,
        color: color,
      });
    },
  },
};
</script>
